import React from 'react';
import { Box, Card, useTheme, makeStyles, Typography } from '@material-ui/core';
import { history } from '../../helpers';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    '&:nth-child(1)': {
      marginBottom: theme.spacing(2)
    }
  },
  cardTitle: {
    color: theme.palette.neutrals.high.main,
  },
  number: {
    color: theme.palette.auxiliary.beige.light,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.high.light,
    '&:hover': {
      color: theme.palette.neutrals.high.dark,
      textDecoration: 'underline',
    },
  },
  seeAllContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.neutrals.high.main
  }
}));

export function IdeaPoints({ ideaTotal, walletAmmount, userId }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  function handleSeeAllIdeas() {
    history.push('/articles');
  }

  function handleSeeAllPoints() {
    history.push(`/wallet/${userId}`);
  }

  return (
    <Box padding="16px 0">
      <Card className={classes.row} style={{ backgroundColor: theme.palette.primary.main }} elevation={0}>
        <Box display="flex" alignItems="center">
          <EmojiObjectsIcon style={{ marginRight: 6, color: theme.palette.primary.dark }} />
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {t('cases')}
          </Typography>
        </Box>
        <Typography variant="subtitle1" className={classes.number}>
          {ideaTotal}
        </Typography>
        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll} onClick={() => handleSeeAllIdeas()}>
            {t('ver_todas')}
          </Typography>
          <KeyboardArrowDown />
        </Box>
      </Card>

      <Card className={classes.row} style={{ backgroundColor: theme.palette.primary.dark }} elevation={0}>
        <Box display="flex" alignItems="center">
          <AccountBalanceWalletIcon style={{ color: theme.palette.primary.main, marginRight: 8 }} />
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {t('pontos').toLowerCase()}
          </Typography>
        </Box>
        {walletAmmount
          ? <Typography variant="subtitle1" className={classes.number}>{parseInt(walletAmmount)}</Typography>
          : <Typography variant="subtitle1" className={classes.number}>0</Typography>
        }
        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll} onClick={() => handleSeeAllPoints()}>
            {t('ver_todas')}
          </Typography>
          <KeyboardArrowDown />
        </Box>
      </Card>
    </Box>
  );
}