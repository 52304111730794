export const rankingConstants = {
  GET_RANKING_REQUEST: 'GET_RANKING_REQUEST',
  GET_RANKING_SUCCESS: 'GET_RANKING_SUCCESS',
  GET_RANKING_FAILURE: 'GET_RANKING_FAILURE',

  GET_RANKING_MOBILE_REQUEST: 'GET_RANKING_MOBILE_REQUEST',
  GET_RANKING_MOBILE_SUCCESS: 'GET_RANKING_MOBILE_SUCCESS',
  GET_RANKING_MOBILE_FAILURE: 'GET_RANKING_MOBILE_FAILURE',

  SET_LIMIT: 'SET_LIMIT',
  SET_PAGE_RANKING: 'SET_PAGE_RANKING',
  SET_FILTER: 'SET_FILTER',
  SET_TYPE_RANKING: 'SET_TYPE_RANKING',
  RANKING_CLEAR_PAGING: 'RANKING_CLEAR_PAGING'
};
