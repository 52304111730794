import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../../../hooks';
import { unMasker } from '../../../helpers';
import config from '../../../config';
import {
  ideaCreateActions,
  multiAreaCascadeActions,
  ideaCreateDialogsActions,
  fluxOfIdeasActions,
} from '../../../actions';

export function useCreateIdeaController() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const [loadingCreateIdea, setLoadingCreateIdea] = useState(false);
  const ideaCreate = useSelector((state) => state.ideaCreate);
  const { loadingFlux } = useSelector(state => state.fluxIdeas);
  const { fields } = useSelector(state => state.multiAreaCascade);
  const { dialogSendIdea, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);
  const isSendIdeaDialog = fromHistory[fromHistory.length -1] === 'sendIdea';

  async function createIdea() {
    setLoadingCreateIdea(true);

    if (fields.map(field => field.selectedBusinessUnit).length === 0 || !ideaCreate.challenge) {
      return;
    }

    if (personalizedClassification.map(item => item.selectedClassification).length === 0 || !ideaCreate.personalizedClassifications) {
      return;
    }

    if (ideaCreate?.challenge?.notChallenge) {
      delete ideaCreate.challenge;
    }

    if (!config.SHOW_IDEAS_BY_STEP_FLUX) {
      delete ideaCreate.estimatedFinancialReturn;
      delete ideaCreate.estimatedExecutionCost;
      delete ideaCreate.problem;
      delete ideaCreate.solution;
    }

    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS) {
      delete ideaCreate.articleQuestions;
    }

    if (!ideaCreate.cardThumbnail?.id) {
      delete ideaCreate.cardThumbnail;
    }

    ideaCreate.businessUnitLocal = fields.map(field => field.selectedBusinessUnit);

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      await dispatch(fluxOfIdeasActions.createIdeaInFlux({
        ...ideaCreate,
        estimatedFinancialReturn: unMasker(ideaCreate?.estimatedFinancialReturn),
        estimatedExecutionCost: unMasker(ideaCreate?.estimatedExecutionCost),
        innovationQuestions: ideaCreate.innovationQuestions.map(item => {
          if (item._id === '65d428327aee51a950e319b5') {
            return {
              ...item,
              valuesToSelect: item.valuesToSelect.map(selected => selected.label)
            }
          }
          return item;
        })
      }, t('sua_ideia_foi_enviada')));
      setLoadingCreateIdea(false);
    } else {
      await dispatch(ideaCreateActions.createIdea(ideaCreate, t('sua_ideia_foi_enviada')));
      setLoadingCreateIdea(false);
    }

    dispatch(ideaCreateDialogsActions.close());
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
    dispatch(ideaCreateActions.getArticleQuestions());
  }

  async function createDraft() {
    setLoadingCreateIdea(true);

    const hasSelectedBuls = fields.filter(field => field.selectedBusinessUnit).length > 0;
    const multiAreaFields = hasSelectedBuls && fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit);

    if (!config.SHOW_IDEAS_BY_STEP_FLUX) {
      delete ideaCreate.estimatedFinancialReturn;
      delete ideaCreate.estimatedExecutionCost;
      delete ideaCreate.problem;
      delete ideaCreate.solution;
    }

    if (!ideaCreate.cardThumbnail?.id) {
      delete ideaCreate.cardThumbnail;
    }

    let paramsDraft = {
      ...ideaCreate,
      businessUnitLocal: multiAreaFields,
      estimatedFinancialReturn: unMasker(ideaCreate?.estimatedFinancialReturn),
      estimatedExecutionCost: unMasker(ideaCreate?.estimatedExecutionCost),
      innovationQuestions: ideaCreate.innovationQuestions.map(item => {
        if (item._id === '65d428327aee51a950e319b5') {
          return {
            ...item,
            valuesToSelect: item.valuesToSelect.map(selected => selected.label)
          }
        }
        return item;
      })
    }

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      await dispatch(ideaCreateActions.createDraft(paramsDraft, t('ideia_salva_em_rascunhos')));
      setLoadingCreateIdea(false);
    } else {
      await dispatch(ideaCreateActions.createDraft(ideaCreate, t('ideia_salva_em_rascunhos')));
      setLoadingCreateIdea(false);
    }
    
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
    dispatch(ideaCreateActions.getArticleQuestions());
  }

  function redirectToEditTitle() {
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditArea() {
    dispatch(ideaCreateDialogsActions.openDialogMultiArea(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditChallenge() {
    dispatch(ideaCreateDialogsActions.openDialogChallenge(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditClassification() {
    dispatch(ideaCreateDialogsActions.openDialogClassification(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditLinks() {
    dispatch(ideaCreateDialogsActions.openDialogLink(!isSendIdeaDialog && 'sendIdea'));
  }
  
  function redirectToEditPhoto() {
    dispatch(ideaCreateDialogsActions.openDialogPhoto(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditVideo() {
    dispatch(ideaCreateDialogsActions.openDialogVideo(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToEditFile() {
    dispatch(ideaCreateDialogsActions.openDialogFile(!isSendIdeaDialog && 'sendIdea'));
  }

  function redirectToReviewIdea() {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog());
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
    dispatch(ideaCreateActions.clearAll());
    dispatch(ideaCreateActions.clearClassification());
  }
  
  return {
    ideaCreate,
    dialogSendIdea,
    loadingFlux,
    loading: ideaCreate.loading,
    isSendIdeaDialog,
    loadingCreateIdea,
    createIdea,
    createDraft,
    redirectToEditTitle,
    redirectToEditArea,
    redirectToEditChallenge,
    redirectToEditClassification,
    redirectToEditPhoto,
    redirectToEditLinks,
    redirectToEditFile,
    redirectToEditVideo,
    redirectToReviewIdea,
    previous,
    close
  }
}