import React from 'react';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ImagineSlider from '../Shared/ImagineSlider';
import { ArticleNextArrow, ArticlePrevArrow } from '../Articles/ArticleSliderOptions';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { ContentCard } from './ContentCard';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginTop: theme.spacing(2)
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
}));

export function ContentHighLight() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const { contents } = useSelector(state => state.contents);

  return (
    <>
      <Card className={classes.card} elevation={0}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {t('conteudos')}
          </Typography>
        </Box>
      </Card>

      <Box style={{ marginBottom: 20 }}>
        {width > config.RESPONSIVE_MOBILE ? (
          <ImagineSlider
            settings={{
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 3,
              slidesToScroll: 1,
              adaptiveHeight: true,
              autoplay: true,
              autoplaySpeed: 7000,
              nextArrow: <ArticleNextArrow />,
              prevArrow: <ArticlePrevArrow />,
            }}
          >
            {contents?.map((item, index) => <ContentCard key={index} item={item} />)}
          </ImagineSlider>
        ) : (
          contents?.map((item, index) => <ContentCard key={index} item={item} />)
        )}
      </Box>
    </>
  );
}
