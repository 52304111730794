import { walletConstants } from '../constants';
import { walletService } from '../services';

export const walletActions = {
  getWallets,
  setQuantityPerPage,
  setPageWallet,
  getPoints,
  setStatus,
  setPeriod,
  setQuery,
  getWalletsMobile,
  clearState,
  getUserPoints,
  getUserPointsRequestStart,
  clearPagingInWallet
};

function getWallets(id, page, quantityPerPage, filter, sort, query) {
  return (dispatch) => {
    dispatch({ 
      type: walletConstants.GET_WALLET_REQUEST 
    })

    walletService.getWallets(id, page, quantityPerPage, filter, sort, query).then(
      (data) => {
        dispatch(success(data.data, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(wallets, paging) {
    const payload = {
      wallets,
      paging,
    };

    return { type: walletConstants.GET_WALLET_SUCCESS, payload };
  }

  function failure(error) {
    return { type: walletConstants.GET_WALLET_FAILURE, error };
  }
}

function getPoints() {
  return (dispatch) => {
    walletService.getPoints().then(
      (points) => {
        dispatch(success(points[0]));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(points) {
    const payload = {
      points,
    };

    return { type: walletConstants.GET_POINTS_SUCCESS, payload };
  }

  function failure(error) {
    return { type: walletConstants.GET_POINTS_FAILURE, error };
  }
}

function getUserPoints(id) {
  return dispatch => {
    walletService.getUserPoints(id)
      .then((res) => {
        dispatch({
          type: walletConstants.GET_POINTS_SUCCESS, 
          payload: res[0],
        })
      })
      .catch((err) => {
        dispatch({
          type: walletConstants.GET_POINTS_FAILURE, 
          payload: {
            points: {
              amount: 0,
              count: 0,
              _id: id,
            }
          }
        })
      })
  }
}

function setQuantityPerPage(quantityPerPage) {
  const payload = {
    quantityPerPage,
  };
  return { type: walletConstants.SET_QUANTITY_PER_PAGE, payload };
}

function setPageWallet(page) {
  const payload = {
    page,
  };
  return { type: walletConstants.SET_PAGE_WALLET, payload };
}

function setStatus(status) {
  const payload = {
    status,
  };

  return { type: walletConstants.SET_STATUS, payload };
}

function setPeriod(period) {
  const payload = {
    period,
  };

  return { type: walletConstants.SET_PERIOD, payload };
}

function setQuery(query) {
  const payload = {
    query,
  };

  return { type: walletConstants.SET_QUERY, payload };
}

function getWalletsMobile(id, page, quantityPerPage, filter, sort, query) {
  return (dispatch) => {
    dispatch(request())
    walletService.getWallets(id, page, quantityPerPage, filter, sort, query).then(
      (data) => {
        dispatch(success(data.data, data.paging));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(){
    return { type: walletConstants.GET_WALLET_MOBILE_REQUEST }
  }

  function success(wallets, paging) {
    const payload = {
      wallets,
      paging,
    };

    return { type: walletConstants.GET_WALLET_MOBILE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: walletConstants.GET_WALLET_MOBILE_FAILURE, error };
  }
}

function clearState() {
  return dispatch => {
    dispatch({
      type: walletConstants.CLEAR_WALLET
    })
  }
}

function getUserPointsRequestStart() {
  return dispatch => {
    dispatch({ 
      type: walletConstants.GET_WALLET_REQUEST 
    })
  }
}

function clearPagingInWallet() {
  return dispatch => {
    dispatch({ type: walletConstants.WALLET_CLEAR_PAGING });
  }
}