import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import Info from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import { EditUpload, EditUploadButton } from './../../components/Upload';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import dialogFileSvg from '../../assets/imgs/dialog-file.svg';

export const IdeaEditGeneralDocs = () => {
  const classes = ideaEditStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <DescriptionIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('documentos')}</Typography>
          </Box>
          <EditUploadButton 
            type="file"
            variant="text"
            text={t('adicionar_documento')}
            icon={<AddCircleOutlinedIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <EditUpload
            type="file"
            img={dialogFileSvg}
            name="arquivo"
            message=""
            accept=".pdf,application/pdf,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,application/msword,.ppt,application/vnd.ms-powerpoint,.pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            maxSize={26214400}
          />
          <Box display="flex" alignItems="center" className={classes.info}>
            <Info style={{fontSize: 15, marginRight: 7 }}/>
            <Typography variant="caption">
              {t('o_arquivo_deve_ser_em_formato')}{' '}
              <strong>PDF</strong>,{' '}
              <strong>PPT</strong>,{' '}
              <strong>EXCEL</strong>,{' '}
              <strong>WORD</strong>{' '}{t('e')}{' '}
              {t('possuir_tamanho_maximo_de')}{' '}<strong>5mb</strong>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
