import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Button, TextField, IconButton } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import { Autocomplete } from '@material-ui/lab';
import { ideaEditStyles } from './IdeaEditStyles';
import { AvatarIconWithText } from '../Shared';
import { ideaUpdateActions } from "./../../actions";

export const IdeaEditGeneralParticipants = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ideaUpdate } = useSelector(state => state);

  function handleChangeParticipant(data, index) {
    if (data) {
      dispatch(ideaUpdateActions.changeFieldParticipant(data.option, index));
    } else {
      dispatch(ideaUpdateActions.clearFieldParticipant(index));
    }
  }

  function handleAddParticipant() {
    dispatch(ideaUpdateActions.addNewParticipant());
  }

  function handleRemoveParticipant(value) {
    return () => {
      dispatch(ideaUpdateActions.removeParticipant(value));
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <AvatarIconWithText
            icon={<GroupIcon color="primary" />}
            text={t('participantes')}
            variant="h3"
          />
          <Button
            variant="text"
            color="primary"
            startIcon={<AddCircleOutlinedIcon />}
            onClick={handleAddParticipant}
          >
            {t('adicionar_participantes')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {ideaUpdate?.contributors.map((item, index) => (
              <Fragment key={index}>
                <Grid item xs={11}>
                  <Autocomplete
                    loadingText={t('carregando')}
                    noOptionsText={t('sem_opcoes')}
                    clearText={t('excluir_desafio')}
                    clearOnEscape={true}
                    options={ideaUpdate.loadParticipants}
                    loading={ideaUpdate.loadParticipants.length === 0}
                    getOptionLabel={(option) => option.name || ''}
                    defaultValue={{ name: item.name, value: item.value }}
                    getOptionSelected={(_option, value) => ideaUpdate?.contributors === value}
                    onChange={(_e, _items, _options, details) => handleChangeParticipant(details, index)}
                    renderInput={(params) => <TextField {...params} label={t('participante')} variant="filled" />}                 
                  />
                </Grid>
                {ideaUpdate?.contributors.length > 0 && (
                  <Grid item xs={1}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      <IconButton onClick={handleRemoveParticipant(item.value)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
