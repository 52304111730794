import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';

import { QualificationCard, ResourcesCard } from '../Qualification';
import { Card } from './styles';
import { EmptyState } from '../../Shared';
import { fluxOfIdeasActions } from '../../../actions';
import { challengesIcon }  from '../../../assets/imgs';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';

export function QualificationLayout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { _id: userId, typeProfile: { type } } = useSelector((state) => state.user);
  const { ideaDetailsFlux } = useIdeaDetailsController();

  useEffect(() => {
    if (ideaDetailsFlux?.personalizedEvaluationGroup?._id || ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.personalizedEvaluationsGroups[0]) {
      dispatch(fluxOfIdeasActions.getEvaluationsByGroup(
        ideaDetailsFlux?.personalizedEvaluationGroup?._id || ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.personalizedEvaluationsGroups[0]
      ));
    }
  }, [ideaDetailsFlux]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {t('qualificar_ideia').toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(ideaDetailsFlux?.currentStepFlux?.evaluatorsInStep?.filter((item) => item._id === userId)?.length > 0) || type === 'admin' ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <QualificationCard />
            </Grid>
            <Grid item xs={12}>
              <ResourcesCard />
            </Grid>
          </Grid>
        ) : (
          <Card elevation={0} style={{ padding: 32 }}>
            <EmptyState
              icon={challengesIcon}
              title={t('nao_ha_nenhuma_acao_pendente_para_seu_usuario_nessa_etapa')}
              description={t('quando_existir_uma_avaliacao_pendente_ela_sera_exibida_aqui')}
            />
          </Card>
        )}
      </Grid>
    </Grid>
  );
}