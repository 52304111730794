import React from 'react';
import { makeStyles, withStyles, Box, Container, Grid, Tabs } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import {
  BackNavAdmin,
  CurationTitle,
  CurationTable,
  SkeletonTable,
  EmptyState,
  CurationFilter,
  ClassificationFilter,
  Search
} from '../../components';
import { useTranslation } from 'react-i18next';
import ListIcon from '@material-ui/icons/List';
import { curationIcon } from '../../assets/imgs';
import { useCurationController } from './hooks/useCurationController';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
}));

const Tab = withStyles({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase',
  }
})(MuiTab);

function CurationIdeasMatPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    loading,
    ideas,
    selectedTab,
    stepsSelected,
    challengeSelected,
    occupationAreaSelected,
    setSelectedTab,
    searchIdea,
    handleChangePage,
    handleChangeRowsPerPage
  } = useCurationController();
  const hasIdeas = ideas.length > 0;

  return (
    <>
      <BackNavAdmin title={t('curadoria')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              centered
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)} 
            >
              <Tab value="LISTING" label={t('listagem_de_ideias')} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <CurationTitle
              title={t('lista_de_ideias')}
              icon={<ListIcon color="primary" />}
              info={t('na_listagem_e_possivel_visualizar_todas_as_ideias_filtrando_as_por_status_e_por_nome_ou_desafio')}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" justifyContent="flex-start">
                <CurationFilter
                  selectedTab={selectedTab}
                  stepsSelected={stepsSelected}
                  challengeSelected={challengeSelected}
                  occupationAreaSelected={occupationAreaSelected}
                />
                <ClassificationFilter
                  selectedTab={selectedTab}
                  stepsSelected={stepsSelected}
                  challengeSelected={challengeSelected}
                  occupationAreaSelected={occupationAreaSelected}
                />
              </Box>
              <Search
                query={searchIdea}
                placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loading && <SkeletonTable cols={6} />}

            {!loading && hasIdeas && selectedTab === 'LISTING' && (
              <CurationTable
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
            
            {!loading && !hasIdeas && (
              <Box paddingTop="2rem">
                <EmptyState
                  icon={curationIcon}
                  description={t('ainda_nao_ha_ideias_por_aqui')}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { CurationIdeasMatPage };
