import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fluxOfIdeasActions, ideaActions } from '../../../actions';
import config from '../../../config';

export function useIdeaDetailsController() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, page } = useParams();
  const { t } = useTranslation();
  const ideaCreate = useSelector((state) => state.ideaCreate);
  const { ideaDetails, loading } = useSelector((state) => state.idea);
  const { ideaDetailsFlux, loadingFlux } = useSelector((state) => state.fluxIdeas);
  const stepflux = config.SHOW_IDEAS_BY_STEP_FLUX;
  const hasIdeaFlux = stepflux ? ideaDetailsFlux : ideaDetails;
  const hasLoadingFlux = stepflux ? loadingFlux : loading;

  function handleEditIdea() {
    history.push(`/idea-edit/${hasIdeaFlux._id}`);
  }

  async function handleSendIdea() {
    await dispatch(fluxOfIdeasActions.createIdeaInFlux({
      ...ideaCreate,
      innovationQuestions: ideaCreate.innovationQuestions,
      estimatedFinancialReturn: ideaCreate?.estimatedFinancialReturn,
      estimatedExecutionCost: ideaCreate?.estimatedExecutionCost
    }, t('sua_ideia_foi_enviada')));

    if (stepflux) {
      dispatch(fluxOfIdeasActions.getIdeaInFlux(id));
    } else {
      dispatch(ideaActions.getIdeaDetails(id));
    }
  }

  return {
    id,
    page,
    ideaDetails,
    ideaDetailsFlux,
    loading,
    loadingFlux,
    hasIdeaFlux,
    hasLoadingFlux,
    stepflux,
    handleEditIdea,
    handleSendIdea
  }
}