import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  makeStyles,
  withStyles,
  InputAdornment,
  FormControl,
  IconButton,
  FormHelperText,
  FilledInput,
  Hidden,
  CircularProgress,
  Box,
  Divider
} from '@material-ui/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import MicrosoftLogin from 'react-microsoft-login';

import { authenticationActions } from '../../actions/authentication.actions';
import config from '../../config';
import loginImage from '../../assets/imgs/ancar-login-banner.png';
import logoImagine from '../../assets/imgs/logo.png';
import logoOffice from '../../assets/imgs/microsoft-365.svg';
import logoGoogle from '../../assets/imgs/google.svg';
import clsx from 'clsx';
import { FooterAuthentication } from '../../components/Shared/FooterAuthentication';
import { deepOrange, red } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@material-ui/icons/Autorenew';
// import GoogleBtn from './GoogleBtn';
import {
  GoogleLogin,
  GoogleLogout
} from 'react-google-login';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { alertActions } from '../../actions/alert.actions';
import { LoadingButton } from '../../components/Shared/LoadingButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  loginGrid: {
    backgroundColor: 'white',
  },
  loginContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  loginTitle: {
    fontSize: 28.83,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: 14,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  spaceBottom: {
    paddingBottom: theme.spacing(3),
  },
  presentation: {
    padding: theme.spacing(0, 12),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 60,
  },
  containerForm: {
    margin: theme.spacing(3, 0),
  },
  googleButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImagine: {
    width: '100%',
    maxWidth: 220,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  containerLogoImagine: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  containerDivider: {
    position: 'relative',
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(0, 7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  divider: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      right: 0,
    },
    '&::before': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      left: 0,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  containerImageBottom: {
    marginLeft: -15,
    marginRight: -15,
    marginBottom: theme.spacing(2),
  }, 
  fullWidth: {
    width: '100%'
  },
  descriptionSupport: {
    color: theme.palette.neutrals.low.light,
    textDecoration: 'none',
    textAlign:'center',
    transition: 'all ease-out .3s',
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  infoIcon:{
    fontSize:'0.9em' 
  },
  loading:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetPassword: {  
    width: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.secondary.dark,
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
mainBackgroundColor: {
    backgroundColor: theme.palette.primary.dark
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: deepOrange[500],
    borderColor: deepOrange[500],
    width: '100%',
    fontWeight: 600,
  },
}))(Button);

const ColorButtonGoogle = withStyles((theme) => ({
  root: {
    color: red[500],
    borderColor: red[500],
    width: '100%',
    fontWeight: 600,
  },
}))(Button);

function LoginPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const authentication = useSelector((state) => state.authentication);
  const url = useLocation()
  const [token, setToken] = useState(url.hash);
  const [shouldRenderOffice365, setShouldRenderOffice365] = useState(false);
  const TIME_TO_RENDER_OFFICE_BUTTON = 3000;

  const { loading } = authentication;

  function onSubmit({ email, password }) {
    if (email && password) {
      dispatch(authenticationActions.login(email, password, t('email_ou_senha_esta_incorreta')));
    }
  }

  function authHandler(err, data, msalInstance) {
    if (err !== null) return;
    let access_token = data?.accessToken || data?.authResponseWithAccessToken?.accessToken;
    loginOffice365(access_token);
  }

  function loginOffice365(token) {
    dispatch(authenticationActions.loginOffice365(token));
  }

  function authHandlerGoogle(err, data) {
    loginGoogle(data.accessToken);
  }

  function loginGoogle(token) {
    dispatch(authenticationActions.loginGoogle(token));
  }

  function redirectLoginGoogle() {
    dispatch(authenticationActions.redirectLoginGoogle());
  }

  useEffect(() => {
    if (url.hash) {
    let _token = url.hash.split('#token=')[1].split("&")[0];
    loginGoogle(_token);
  }
    else {
    dispatch(authenticationActions.logout());
  }
}, [dispatch, url.hash]);

useEffect(() => {
    setTimeout(() => setShouldRenderOffice365(true), TIME_TO_RENDER_OFFICE_BUTTON)
  }, []);

const clientId = `${config.GOOGLE_ID}`;
const gerror = response => {
  console.error(response) // eslint-disable-line
}

  return (
    <Box className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        <Grid item xs={8} md={6} lg={8} className={classes.mainBackgroundColor}>
        <Hidden smDown>
            <img width="100%" src={loginImage}  alt="arcar_inovation"></img>
          </Hidden>
          </Grid>
        <Grid item xs={12} md={6} lg={4} className={classes.loginGrid}>
          <div className={classes.loginContainer}>
            <div className={classes.content}>
            {config.LANGUAGE_SELECT && <div style={{ position: 'absolute', top: 1, right: 10 }}>
                <LanguageSelect />
              </div>}
              <div className={clsx(classes.title, classes.loginTitle)}>
                login
              </div>
              <div className={classes.subtitle} style={{ fontWeight: 'bold' }}>
                {t('seja_bem_vindo_ao')} 
              </div>
              <div className={classes.subtitle}>
                {t('faca_login_para_acessar_sua_conta')}, {' '}
                {t('atraves_do_seu_email_corporativo')}
              </div>


              {config.SHOW_OFFICE365_FORM && (
                shouldRenderOffice365 ? (<MicrosoftLogin
                clientId={config.OFFICE365_ID}
                tenantUrl={config.OFFICE365_AUTH_URL}
                authCallback={authHandler}
                // debug={true}
                forceRedirectStrategy={true}
              >
                <ColorButton
                  style={{ textTransform: 'lowercase' }}
                  variant="outlined"
                  disabled={loading}
                >
                  {loading ?(
                      <div className={classes.loading}>
                        <CircularProgress 
                          size={20}
                          style={{ marginRight: 10 }} 
                        />
                        {t('carregando...')}
                      </div>
                  ) : (
                      <div className={classes.loading}>
                  <img
                    src={logoOffice}
                    alt="office 365"
                    style={{ marginRight: 10 }}
                  />
                        <span>{t('entrar_com')} Office 365</span>
                      </div>
                  )}
                </ColorButton>
                </MicrosoftLogin>) : (<Box display='flex' justifyContent='center'>
                  <CircularProgress />
                </Box>)
              )}
              {
                config.SHOW_OFFICE365_FORM && config.SHOW_GOOGLE_FORM   
              && (
                <div className={classes.containerForm}>
                  <div
                    className={clsx(
                      classes.containerDivider,
                      classes.spaceBottom
                    )}
                  >
                    <span className={classes.divider}>{t('ou')}</span>
                  </div>
                </div>
                )
              }
              {config.SHOW_GOOGLE_FORM && (
              <center>
                <ColorButtonGoogle
                  style={{ textTransform: 'lowercase' }}
                  variant="outlined"
                  disabled={loading}
                  onClick={redirectLoginGoogle}
                >
                  {loading ?(
                      <div className={classes.loading}>
                        <CircularProgress 
                          size={20}
                          style={{ marginRight: 10 }} 
                        />
                        {t('carregando...')}
                      </div>
                  ) : (
                      <div className={classes.loading}>
                  <img
                    src={logoGoogle}
                    alt="google"
                    style={{ marginRight: 10 }}
                  />
                        <span>{t('entrar_com')} Google</span>
                      </div>
                  )}
                </ColorButtonGoogle>
              </center>
              )}

              {
                  ((config.SHOW_OFFICE365_FORM && config.LOGIN_FORM) ||
                  (config.SHOW_GOOGLE_FORM && config.LOGIN_FORM)) && (
                <div className={classes.containerForm}>
                  <div
                    className={clsx(
                      classes.containerDivider,
                      classes.spaceBottom
                    )}
                  >
                    <span className={classes.divider}>{t('ou')}</span>
                  </div>
                </div>
                )
              }
              {/* {config.LOGIN_FORM && ( */}
                <div className={classes.containerForm}  style={{ display: config.LOGIN_FORM? 'block': 'none'}} >
                  <div className={classes.subtitle}>
                    <InfoIcon className={classes.infoIcon} />
                    <b>{t('primeiro_acesso')}</b> {t('digite_seu')} E-mail{' '}
                    {t('no_primeiro_campo_e_use_o')} E-mail{' '}
                    {t('como_senha_para_acessar')}.
                  </div>

                  {/* <div
                    className={clsx(
                      classes.containerDivider,
                      classes.spaceBottom
                    )}
                  >
                    <span className={classes.divider}>ou</span>
                  </div> */}
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField
                      type="email"
                      name="email"
                      id="email"
                      placeholder="e-mail"
                      variant="filled"
                      fullWidth
                      inputRef={register({
                        required: t('o_campo_login_e_obrigatorio'),
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                      className={classes.spaceBottom}
                    />
                    <FormControl
                      variant="filled"
                      error={!!errors.password}
                      margin="normal"
                      className={classes.spaceBottom}
                    >
                      <FilledInput
                        id="outlined-adornment-password"
                        placeholder={t('senha')}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        inputRef={register({
                          required: t('a_senha_e_obrigatoria'),
                        })}
                        startAdornment={
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="helper-password">
                        {errors.password && errors.password.message}
                      </FormHelperText>
                    </FormControl>
                    {loading ?
                      <LoadingButton />
                    : 
                      <Button
                        style={{ textTransform: 'lowercase' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {t('entrar')}
                      </Button>
                    }
                <br/>
                <br/>
              <Link
                to="/password-recover"
                className={classes.resetPassword}
              >
                <div>{t('esqueceu_a_senha')}</div>
              </Link>
                  </form>
                </div>
            </div>
            <Box display="flex" alingItems="center" justifyContent="space-between" padding="0 1rem">
              <a
                className={classes.descriptionSupport}
                href={'mailto:' + 'suporte@imagineinovacao.com.br'}
              >
                {t('fale_conosco')} - {t('suporte')}
              </a>
              <Box padding="0px 8px">
                <Divider orientation="vertical"  />
              </Box>
              {/* <Link to="/custom-privacy-policy" className={classes.descriptionSupport}>
                <div>{t('politicas_e_termos_de_privacidade')}  |</div>
              </Link> */}
              <Link to="/regulation" className={classes.descriptionSupport}>
                <div>{t('politicas_e_termos_de_privacidade_imagine')}</div>
              </Link>
            </Box>
            <FooterAuthentication />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export { LoginPage };
