import config from '../config';
import axios from 'axios';
import { requestOptions, handleResponse, requestHeaders } from '../helpers';

export const authenticationService = {
  login,
  loginOffice365,
  loginGoogle,
  register,
  registerOffice365,
  registerGoogle,
  logout,
};

function login(email, password) {
  const body = `grant_type=password&username=${email}&password=${password}&client_id=${config.CLIENT_ID}&client_secret=${config.CLIENT_SECRET}`;
  return axios
    .post(`${config.URL_TOKEN}`, body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then(handleResponse)
    .then((token) => {
      localStorage.setItem('token', JSON.stringify(token));

      return token;
    });
}


function loginOffice365(token) {
  const body = `grant_type=password&username=${token}&password=${token}&client_id=${config.CLIENT_ID}&client_secret=${config.CLIENT_SECRET}`;
  return axios
    .post(`${config.URL_TOKEN}`, body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then(handleResponse)
    .then((token) => {
      localStorage.setItem('token', JSON.stringify(token));

      return token;
    });
}

function loginGoogle(token) {
  const body = `grant_type=password&username=${token}&password=${token}&client_id=${config.CLIENT_ID}&client_secret=${config.CLIENT_SECRET}`;
  return axios
    .post(`${config.URL_TOKEN}`, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(handleResponse)
    .then((token) => {
      localStorage.setItem('token', JSON.stringify(token));

      return token;
    });
}

function register(user) {
  return fetch(`${config.URL_BASE}/register`, requestOptions('POST', user))
    .then(handleResponse)
    .then((token) => {
      localStorage.setItem('token', JSON.stringify(token.token));

      return token;
    });
}

function registerOffice365(user) {
  return axios
    .put(`${config.URL_BASE}/user`, user, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}
function registerGoogle(user) {
  return axios
    .put(`${config.URL_BASE}/user`, user, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}
function logout() {
  localStorage.removeItem('token');
}
