import React, { useState } from 'react';
import {
  Box,
  CardContent,
  CardMedia,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  useTheme,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import dialogPhotoSvg from '../../assets/imgs/illustration-image-drag-n-drop.svg';
import { ReactComponent as PhotoIcon } from '../../assets/imgs/filled-image_24dp.svg';
import InfoIcon from '@material-ui/icons/Info';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { ImageCrop } from '../ImageCrop';
import infoSvg from '../../assets/imgs/info.svg';
import { ideaCreateActions, ideaUpdateActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2, 10),
  },
  contenteCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageChildren: {
    padding: '60px 80px',
    backgroundColor: theme.palette.neutrals.high.lightest,
    textAlign: 'center',
    fontSize: 16,
  },
  textImage: {
    color: theme.palette.primary.main,
  },
  successDropZone: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontWeight: 600,
  },
  errorDropZone: {
    color: theme.palette.error.main,
    fontSize: 30,
    fontWeight: 600,
  },
  info: {
    paddingTop: theme.spacing(6),
    fontSize: 13,
    display: 'flex',
    color: theme.palette.neutrals.low.medium,
  },
  infoBold: {
    fontWeight: 600,
  },
  message: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    top: -16,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: 10,
  },
  media: {
    width: '100%',
    paddingTop: ({type}) => type === 'thumbnail' ? '56.25%' : '18.75%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  iconRemove: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  dialogTitle: {
    fontSize: 20,
    color: 'dialogTitle',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
  },
  dialogDescription: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  dialogActions: {
    padding: theme.spacing(4, 0),
    justifyContent: 'center',
  },
  thumbTitle: { padding: '0 40px' }
}));

export function CardThumbnail({ type, image, isEdit = false }) {
  const dispatch = useDispatch();
  const classes = useStyles({ type });
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const aspectRatio = 16 / 9;

  function handleClickOpenDialog() {
    setOpenDialog(true);
  }

  function handleImageCrop(blob) {
    let data = {
      ...file,
      file: new File([blob], file.name, {
        type: blob.type,
      }),
      readableSize: filesize(blob.size),
      preview: URL.createObjectURL(blob),
      type: blob.type,
    }

    if (isEdit) {
      dispatch(ideaUpdateActions.addInnovationThumbnail(data));
    } else {
      dispatch(ideaCreateActions.setCardThumbnail(data));
    }
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function removeImage() {
    if (isEdit) {
      dispatch(ideaUpdateActions.removeInnovationThumbnail(type));
    } else {
      dispatch(ideaCreateActions.removeCardThumbnail(type)); 
    }
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <Box></Box>;
    }

    if (isDragReject) {
      handleClickOpenDialog();
      return;
    }

    return (
      <Box className={classes.message}>
        <Box className={classes.successDropZone}>
          {t('solte_a_image_aqui')}
        </Box>
      </Box>
    );
  }

  function onUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
    }));
    setFile({ id: newUploadedFiles[0].id, name: newUploadedFiles[0].name });
    setSrc(newUploadedFiles[0].preview);
    setOpen(true);
  }

  function closeCropper() {
    setOpen(false);
  }

  return (
    <Box>
      <Typography variant="subtitle1">{t('miniatura_do_card').toUpperCase()}</Typography>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xs"
      >
        <DialogContent>
          <Box className={classes.dialogHeader}>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon style={{ color: theme.palette.neutrals.low.medium }} />
            </IconButton>
          </Box>
          <DialogContentText className={classes.dialogContent}>
            <Box>
              <img src={infoSvg} alt="info" />
            </Box>
            <Box className={classes.dialogTitle}>
              {t('ops')}! {t('aconteceu_um_erro')}
            </Box>
            <Box className={classes.dialogDescription}>
              {t('nao_foi_possivel_fazer_o_upload')}.{' '}
              {t('tipo_de_arquivo_nao_suportado_ou_execedeu_o_tamanho_maximo')}.
            </Box>
          </DialogContentText>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={handleCloseDialog}
              color="primary"
              variant="contained"
            >
              {t('ok')}, {t('entendi')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <CardContent className={classes.content}>
        <Box className={classes.contenteCenter}>
          <ImageCrop
            src={src}
            dispatch={handleImageCrop}
            open={open}
            close={closeCropper}
            aspect={aspectRatio}
          />
          {image?.preview || image?.url ? (
            <>
              {image.preview && (
                <Box width="100%">
                  <CardMedia className={classes.media} image={image.preview} />
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PhotoIcon style={{ marginRight: 10 }} />
                    <Box>
                      {image.name} ({image.readableSize})
                    </Box>
                    <IconButton onClick={removeImage}>
                      <DeleteIcon className={classes.iconRemove}/>
                    </IconButton>
                  </Box>
                </Box>
              )}

              {image.url && (
                <Box width="100%">
                  <CardMedia className={classes.media} image={image.url} />
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PhotoIcon style={{ marginRight: 10 }} />
                    <Box>
                      {image.fileName} ({image.size})
                    </Box>
                    <IconButton onClick={removeImage}>
                      <DeleteIcon className={classes.iconRemove}/>
                    </IconButton>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Dropzone accept="image/png, image/jpeg" onDropAccepted={onUpload}>
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <Box style={{ position: 'relative' }}>
                  <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box className={classes.imageChildren}>
                      <Box
                        style={{
                          cursor: 'pointer',
                          outline: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Box style={{ height: 164 }}>
                          <img
                            style={{ height: 164 }}
                            src={dialogPhotoSvg}
                            alt="photoSvg"
                          />
                        </Box>
                        {renderDragMessage(isDragActive, isDragReject)}
                        {t('arraste_seu_arquivo_aqui')} {t('ou')} <br />{' '}
                        <span className={classes.textImage}>
                          {t('procure_em_seu_computador')}
                        </span>
                      </Box>
                    </Box>
                    <Box className={classes.info}>
                      <Box style={{ marginRight: 10 }}>
                        <InfoIcon style={{ color: theme.palette.neutrals.low.lightest }} />
                      </Box>
                      <Box>
                        {t('o_arquivo_deve_ser_em_formato')}{' '}
                        <span className={classes.infoBold}>png</span> {t('e')}{' '}
                        <span className={classes.infoBold}>jpg</span> {t('e')}{' '}
                        {t('possuir_tamanho_maximo_de')}{' '}
                        <span className={classes.infoBold}>5mb</span>, <br />{' '}
                        {t('a_resolucao')}{' '}
                        <span className={classes.infoBold}>
                          {t('recomendada')}
                        </span>{' '}
                        {t('eh_de')}{' '}
                        {t('no_minimo')}{' '}
                        <span className={classes.infoBold}>1280 x 240  </span>pixels
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Dropzone>
          )}
        </Box>
      </CardContent>
    </Box>
  );
}