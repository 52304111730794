import * as randomcolor from 'randomcolor';

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const CustomLabel = ({ viewBox, value, text, type }) => {
  const theme = useTheme();
  const { cx, cy } = viewBox;
  const { t } = useTranslation();
  return (
    <>
      <text
        x={cx}
        y={cy - 8}
        textAnchor="middle"
        dominantBaseline="central"
        fontWeight="bold"
        fontSize={34}
        fill={theme.palette.neutrals.low.light}
      >
        {
          window.location.href.split('report')[1] === '/ideas-financial-return' ?
            `${t('dinheiro')} ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : value
        }
      </text>
      <text
        x={cx}
        y={cy + 20}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="14"
        fill={theme.palette.neutrals.low.light}
      >
        {
          window.location.href.split('report')[1] === '/ideas-financial-return' ?
            `` : ``
        }
        {
          window.location.href.split('report')[1] === '/colaborator' ?
            `colaboradores` : ``
        }
        {
          window.location.href.split('report')[1] === '/ideas-status' ?
            text : ``
        }

      </text>
    </>
  );
};

function PieChartImagine({ data, type }) {
  const history = useHistory();
  const theme = useTheme();
  const colors = [
    theme.palette.auxiliary.blue.light,
    theme.palette.auxiliary.grape.light,
    theme.palette.auxiliary.green.main,
    theme.palette.auxiliary.yellow.light,
    theme.palette.auxiliary.red.light,
    theme.palette.auxiliary.pink.light,
    theme.palette.auxiliary.cyan.light,
    theme.palette.auxiliary.indigo.light,
    theme.palette.auxiliary.blue.main,
    theme.palette.primary.medium,
    theme.palette.auxiliary.green.main,
    theme.palette.auxiliary.blue.main,
    theme.palette.auxiliary.red.main,
    theme.palette.auxiliary.yellow.main,
    theme.palette.auxiliary.orange.main,
    theme.palette.auxiliary.pink.main,
    theme.palette.auxiliary.cyan.main,
    theme.palette.auxiliary.indigo.main,
  ];
  const sum = data.reduce((acc, cur) => {
    return acc + cur.count;
  }, 0);

  const [splittedPathname] = useState(history.location.pathname.split("/"));

 // Numero de legendas multiplicado pela altura de cada uma mais a altura do chart
 const calculatedHeight = data.length * 30 + 300;

  return (
    <ResponsiveContainer height={calculatedHeight}>
      <PieChart width="100%">
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0.8} />
            <stop offset="95%" stopColor={theme.palette.auxiliary.green.main} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Pie
          cx="50%"
          cy="50%"
          data={data}
          dataKey="count"
          legendType="circle"
          innerRadius={20}
          outerRadius={120}
          paddingAngle={3}
          cornerRadius={8}
          labelLine={false}
          label={({ count }) => splittedPathname[splittedPathname.length - 1] === 'colaborator-access-by-month' ? count : `${count}%`}
          fontWeight="600"
          display="flex"
        >
          {data?.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index] || randomcolor()}
            />
          ))} 
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

export { PieChartImagine };
