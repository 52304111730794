import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TimeAgo from 'react-timeago';

import { 
  Tabs,
  Tab,
  Box,
  Button,
  Hidden,
  makeStyles,
  Card,
  Divider,
  Typography,
  Grid,
  Avatar,
  TextField
} from "@material-ui/core";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SendIcon from '@material-ui/icons/Send';

import { engagementActions } from "./../../actions"
import { formatterTimer, history } from '../../helpers';
import { EmptyState } from '../Shared';
import iconEmptyStateComment from '../../assets/imgs/emptystate/illustration_empty-state-comments.svg';
import iconEmptyStateUps from '../../assets/imgs/emptystate/illustration_empty-state-ups.svg';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(3)
  },
  tabs: {
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: theme.palette.neutrals.high.main,
  },
  lineBox: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 4)
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  commentBox: {
    width: '100%',
    borderRadius: 4,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.high.lightest
  },
  commentHeader: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  commentName: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  },
  commentInfo: {
    fontSize: 12,
    color: theme.palette.neutrals.low.medium,
  },
  itemTab: {
    fontWeight: 'bold',
    fontSize: '12px',
    marginLeft: theme.spacing(0.8)
  },
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4, 0),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
    padding: theme.spacing(0, 4, 2, 4)
  },
  ideaComment: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    marginTop: 10
  },
  ideaCommentBtn: {
    height: 47
  }
}))

export const IdeaEngagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const { register, errors, handleSubmit, reset } = useForm();
  const userLogged = useSelector((state) => state.user);
  const language = useSelector((state) => state.language);
  const { loading, upItems, commentItems } = useSelector(state => state.engagement);
  const { hasIdeaFlux } = useIdeaDetailsController();

  function handleChangeTab(tabIndex) {
    setTabIndex(tabIndex)
    //dispatch(engagementActions.setEgagementTab(tabIndex))
  }

  function onSubmitComment(data) {
    const comment = {
      idea: hasIdeaFlux._id,
      user: userLogged._id,
      comment: data.comment,
    };

    dispatch(engagementActions.commentIdea(comment));
    reset();
  }

  return (
    <Fragment>
      <Typography variant="subtitle2" className={classes.title}>
        {t('engajamento')}
      </Typography>

      <Tabs 
        value={tabIndex} 
        onChange={(e, value) => handleChangeTab(value)} 
        aria-label="Tabs de grafico e tabela" 
        indicatorColor="primary"
        className={classes.tabs}
        variant="fullWidth"
      >
        <Tab className={classes.tab} label={(
          <Box display="flex" alignItems="center" justifyContent="center">
            <ThumbUpIcon color={tabIndex === 0 ? "primary" : 'default'} />
            <Typography 
              color={tabIndex === 0 ? "primary" : 'default'}
              className={classes.itemTab}
            >
              {t('voto')}
            </Typography>
          </Box>
        )} aria-controls="Ups" />
        <Tab className={classes.tab} label={(
          <Box display="flex" alignItems="center" justifyContent="center">
            <ChatBubbleIcon color={tabIndex === 1 ? "primary" : 'default'} />
            <Typography 
              color={tabIndex === 1 ? "primary" : 'default'} 
              className={classes.itemTab}
            >
              {t('comentarios')}
            </Typography>
          </Box>
        )} aria-controls="Cometarios" />
      </Tabs>

      { tabIndex === 0 && !loading && (
        <Fragment>
          { upItems.length === 0 ? (
             <Card className={classes.card} elevation={0}>
               <EmptyState
                icon={iconEmptyStateUps}
                description={<>
                  {t('ainda_nao_ha_ups')} <br />
                  {t('na_publicacao')}
                </>}
              />
             </Card>
          ) : (
            <Card className={classes.card} elevation={0}>
              <Box className={classes.cardTitle}>
                { `${upItems.length} ${upItems.length === 1 ? t('up') : t('ups')}` }
              </Box>
              <Divider/>
              <Grid container>
                { upItems.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box className={classes.lineBox}>
                      {item?.attachment?.url ? (
                        <Avatar className={classes.avatar} src={item.attachment.url} />
                      ) : (
                        <Avatar className={classes.avatar} />
                      )}
                      <Typography variant="subtitle2">{item.name}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card> 
          )}
        </Fragment>
      )}

      { tabIndex === 1 && !loading && (
        <Fragment>
          <form
            className={classes.ideaComment}
            onSubmit={handleSubmit(onSubmitComment)}
          >
            <TextField
              error={!!errors.comment}
              id="outlined-basic"
              label={t('adicione_um_comentario') + '...'}
              variant="filled"
              multiline
              fullWidth
              margin="dense"
              name="comment"
              inputRef={register({
                required: t('nao_pode_postar_uma_mensagem_em_branco'),
              })}
              className={classes.ideaCommentText}
              helperText={errors.comment && errors.comment.message}
            />
            <div>
              <Button
                className={classes.ideaCommentBtn}
                color="primary"
                variant="contained"
                type="submit"
              >
                <Hidden smUp>
                  <SendIcon style={{ fontSize: 30 }} />
                </Hidden>
                <Hidden xsDown>{t('publicar')}</Hidden>
              </Button>
            </div>
          </form>

          { commentItems.length === 0 ? (
            <Card className={classes.card} elevation={0}>
              <EmptyState
                icon={iconEmptyStateComment}
                description={<>
                  {t('ainda_nao_ha_comentarios')} <br />
                  {t('na_sua_publicacao')}
                </>}
              />
            </Card>
          ) : (
            <Card className={classes.card} elevation={0}>
              <Box className={classes.cardTitle}>
                { `${commentItems.length} ${commentItems.length === 1 ? t('comentario') : t('comentarios')}` }
              </Box>
              <Divider/>
              <Grid container>
                { commentItems.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box className={classes.lineBox}>
                      {item.user?.attachment?.url ? (
                        <Avatar className={classes.avatar} src={item.user.attachment.url} />
                      ) : (
                        <Avatar className={classes.avatar} />
                      )}
                      <Box className={classes.commentBox}>
                        <Box className={classes.commentHeader}>
                          <Typography 
                            className={classes.commentName}
                            onClick={() => history.push(`/profile/${item.user._id}`)}
                          >
                            {item.user.name}
                          </Typography>
                          <TimeAgo 
                            date={item.createdAt}
                            className={classes.commentInfo}
                            formatter={formatterTimer(language)} 
                          />
                        </Box>
                        <Typography component="p" className={classes.commentInfo}>{item.comment}</Typography>  
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
