import React from 'react';
import { Grid, CircularProgress, Card as MuiCard, styled } from '@material-ui/core';
import {
  IdeaGeneral,
  IdeaResults,
  IdeaExecution,
  IdeaConclusion,
  IdeaEngagement,
  IdeaGeneralEvaluationLayout,
  EmptyState,
  SidebarIdeaDetails,
  QualificationLayout,
  HistoryLayout
} from '../../components';
import {
  SkeletonCardIdeaDetails,
  SkeletonSidebarIdeaDetails
} from '../Skeletons/SkeletonIdeaDetails';
import emptyStateIcon from '../../assets/imgs/emptystate/illustration_empty-state-general-evaluation.svg';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage';

export function FluxOfIdeasLayout() {
  const { page, loadingFlux, ideaDetailsFlux } = useIdeaDetailsController();

  const Card = styled(MuiCard)(({ theme }) => ({
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    borderRadius: 8,
    padding: theme.spacing(4),
  }));
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={3}>
        {loadingFlux ? <SkeletonSidebarIdeaDetails /> : <SidebarIdeaDetails />}
      </Grid>
      <Grid item xs={12} sm={12} md={9}>
        {loadingFlux ? <SkeletonCardIdeaDetails /> : page && (
          <>
            {page === "general" && ideaDetailsFlux?._id && <IdeaGeneral />}

            {page === "qualification" && ideaDetailsFlux?._id && <QualificationLayout />}
          
            {/* 
            {loadingFlux ? <CircularProgress /> : (
              page === "execution" && ideaDetailsFlux._id && !!projects?.length ? 
              (<LinkedProjects projects={projects} />) : (
              <IdeaExecution />
            ))} */}

            {page === "general-evaluation" && ideaDetailsFlux?._id && (<>
              {ideaDetailsFlux?.personalizedEvaluationGroup !== undefined 
                ? <IdeaGeneralEvaluationLayout />
                : (
                  <Card elevation={0}>
                    <EmptyState
                      icon={emptyStateIcon}
                      title="Ainda não há avaliações por aqui!"
                      description="A avaliação geral será exibida aqui quando liberada."
                    />
                  </Card>
                )
              }
            </>)}

            {loadingFlux ? <CircularProgress /> : 
              page === "execution" && ideaDetailsFlux?._id && (
              <IdeaExecution />
            )}

            {page === "conclusion" && ideaDetailsFlux?._id && (
              <IdeaConclusion />
            )}

            {page === "results" && ideaDetailsFlux?._id && (
              <IdeaResults />
            )}

            {page === "engagement" && ideaDetailsFlux?._id && (
              <IdeaEngagement />
            )}

            {page === "history" && ideaDetailsFlux?._id && <HistoryLayout />}
          </>
        )}
      </Grid>
    </Grid>
  );
}