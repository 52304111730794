import React, { useEffect, useMemo, useState } from 'react';
import { Container, makeStyles, Button, Grid, Box, CircularProgress, TextField, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import { BackNavAdmin, ArticleCard, Search, DialogAllCreateIdea } from '../../components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSelector, useDispatch } from 'react-redux';
import { articleActions, ideaCreateActions, ideaCreateDialogsActions } from '../../actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { ButtonBaseMenu, EmptyState } from '../../components/Shared';
import { portfolioIcon } from '../../assets/imgs';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  btnNewArticle: {
    float: 'right',
    padding: theme.spacing(1, 2),
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterContent: {
    width: 400,
    padding: 16
  }
}));

function ArticlesPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ideas, loading, viewEmptyState, classifications } = useSelector(state => state.articlesReducer);
  const { page, sort, limit, filter, hasNextPage } = useSelector(state => state.articlesReducer.paging);
  const { articleQuestions } = useSelector((state) => state.ideaCreate);
  const { dialogTitleDescription } = useSelector((state) => state.ideaCreateDialogs);
  const { personalizedClassifications } = useSelector(state => state.domains)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openFilter, setOpenFilter] = useState(null);

  const filterClassificationsById = useMemo(() => {
    const filtered = classifications.filter(item => item.value !== 'SELECT_ALL' && item.checked).map(item => item.value) ?? null;
    const hasFilter = filtered.length > 0 ? filtered : null;

    return hasFilter;
  }, [classifications]);

  useEffect(() => {
    dispatch(articleActions.getIdeasWithInfiniteScroll(page, sort, limit, filter));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(articleActions.clearAllArticles());
    }
  }, []);

  useEffect(() => {
    if(personalizedClassifications) {
      const personalizedClassificationsFiltered = personalizedClassifications.map((item) => ({
        value: item._id,
        label: item.name,
        checked: false,
      }));
      const selectAllOption = {
        checked: false,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      };
      dispatch(articleActions.loadClassifications([selectAllOption,...personalizedClassificationsFiltered]));
    }
  }, [personalizedClassifications, dispatch]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      dispatch(ideaCreateActions.getArticleQuestions());
    }
  }, [dispatch, dialogTitleDescription]);

  useEffect(() => {
    if (config.SHOW_ADITIONAL_INNOVATION_FIELDS && dialogTitleDescription) {
      if (articleQuestions) {
        dispatch(ideaCreateActions.loadArticleQuestions(articleQuestions?.map((item) => ({
          _id: item._id,
          question: item.question,
          answer: item?.answer ?? '',
          isSelect: item?.isSelect,
          valuesToSelect: item?.valuesToSelect,
          attachmentRequired: item?.attachmentRequired
        }))));
      }
    }
  }, [dispatch, articleQuestions, dialogTitleDescription]);

  useEffect(() => {
    if (!loading && scrollPosition > 0) {
      window.scrollTo(0, scrollPosition);
    }
  }, [loading, scrollPosition]);

  function loadMoreIdeas() {
    if (!loading && hasNextPage) {
      const p = (page * 1) + 1;

      setScrollPosition(window.scrollY);

      dispatch(articleActions.getIdeasWithInfiniteScroll(p, sort, limit, filter, filterClassificationsById));
    }
  }

  function searchIdea(query) {
    dispatch(articleActions.getIdeasWithInfiniteScrollSearch(1, sort, limit, { field: 'name', value: query }));
  }

  function handleOpenDialog(openFunction) {
    dispatch(openFunction())
  }

  function handleClick(e) {
    setOpenFilter(e.currentTarget);
  }

  function handleClose() {
    setOpenFilter(null);
  }

  function handleChangeClassfication(data) {
    if (data) {
      dispatch(articleActions.changeClassifications(data.option))
    } else {
      dispatch(articleActions.clearClassifications())
    }
  }

  async function handleFilterCases() {
    dispatch(articleActions.getIdeasWithInfiniteScroll(page, sort, limit, filter, filterClassificationsById));

    handleClose();
  }

  return (
    <>
      <DialogAllCreateIdea />
      <BackNavAdmin title={!loading ? t('cases') : t('carregando')} pathname="/">
        <Button
          className={classes.btnNewArticle}
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() =>
            handleOpenDialog(ideaCreateDialogsActions.openDialogTitleDescription)
          }
        >
          {t('nova_inovacao')}
        </Button>
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.containerHeader}>
            <ButtonBaseMenu
              click={handleClick}
              open={Boolean(openFilter)}
              onClose={handleClose}
              anchorEl={openFilter}
              icon={<FilterListIcon />}
              titleButton={t('filtro')}
              titleMenu={t('filtrar_por')}
            >
              <Box className={classes.filterContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      fullWidth
                      disableCloseOnSelect
                      loadingText={t('carregando...')}
                      noOptionsText={t('sem_opcoes')}
                      id="checkboxes-personalized-classifications"
                      options={classifications}
                      loading={classifications.length === 0}
                      value={classifications.filter(item => item.checked)}
                      onChange={(e, items, options, details) => handleChangeClassfication(details)}
                      getOptionLabel={(input) => input.label}
                      renderInput={(params) => <TextField {...params} label={t('classificacao')} variant="filled" />}
                      renderTags={(option, state) => {
                        return (<span>{option.length > 1 ? `${option[0].label} +${option.length - 1}` : option[0].label}</span>)
                      }}
                      renderOption={(value) => {
                        return (
                          <li>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckboxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={value.checked}
                            />
                            {value.label}
                          </li>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<FilterListIcon />}
                        onClick={handleFilterCases}
                      >
                        {t('filtrar')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </ButtonBaseMenu>
            <Search
              query={searchIdea}
              value={filter.value}
              placeholder={t('pesquise_pelo_nome_da_ideia')}
            />
          </Grid>
          <Grid item xs={12}>
            {loading && (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="60vh">
                <CircularProgress />
              </Box>
            )}

            {!loading && viewEmptyState && (
              <Box marginTop="2rem">
                <EmptyState
                  icon={portfolioIcon}
                  description={t('ainda_nao_ha_inovacoes_publicados')}
                />
              </Box>
            )}
            
            {!loading && !viewEmptyState && (
              <InfiniteScroll
                dataLength={ideas.length}
                next={loadMoreIdeas}
                hasMore={true}
                scrollThreshold={'90%'}
                style={{ overflow: 'hidden', paddingBottom: 20 }}
              >
                <Grid container spacing={2}>
                  {ideas.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={item._id}>
                      <ArticleCard
                        idea={item}
                        link={`/idea/${item._id}/general`}
                        image={item.cardThumbnail?.url ?? ""}
                        index={index}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ArticlesPage };
