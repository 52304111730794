import { engagementConstants } from "./../constants"
import { engagementService, timelineService } from "./../services"

export const engagementActions = {
  getEngagement,
  setEgagementTab,
  clearErrors,
  commentIdea
}

function getEngagement(type, id) {
  return dispatch => {
    dispatch({
      type: engagementConstants.GET_ENGAGEMENT_ITEMS_REQUEST
    })

    if (type === "up") {
      engagementService.getUps(id)
        .then((response) => {
          dispatch({
            type: engagementConstants.GET_ENGAGEMENT_ITEMS_SUCCESS,
            payload: {
              type,
              data: response
            }
          })
        })
    } else {
      engagementService.getComments(id)
      .then((response) => {
        dispatch({
          type: engagementConstants.GET_ENGAGEMENT_ITEMS_SUCCESS,
          payload: {
            type,
            data: response
          }
        })
      })
    }

  }
}

function setEgagementTab(tabIndex) {
  return dispatch => {
    dispatch({
      type: engagementConstants.SET_ENGAGEMENT_TAB,
      payload: tabIndex
    })
  }
}

function clearErrors() {

}

function commentIdea(comment) {
  return dispatch => {
    dispatch({ type: engagementConstants.SEND_COMMENT_IDEA_REQUEST });

    timelineService.newComment(comment)
      .then(() => {
        dispatch({ type: engagementConstants.SEND_COMMENT_IDEA_SUCCESS });
        dispatch(getEngagement('comment', comment.idea));
      })
      .catch((error) => {
        dispatch({ type: engagementConstants.SEND_COMMENT_IDEA_FAILURE, error });
      });
  }
}