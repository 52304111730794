import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Menu,
  Button,
  makeStyles,
  useTheme,
  withStyles,
  IconButton,
  Typography
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinearProgress from '@material-ui/core/LinearProgress';

import { dashboardActions } from '../../actions';
import { SkeletonDashboardTops } from '../../components/Skeletons';

import { today, beforeMonth, formatDate } from '../../utils/date';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dashboardService } from '../../services';

const BorderLinearProgess = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: 'white',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({ 
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  buttonCsv: {
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: theme.palette.neutrals.high.main
  }
}));

const topAreaItemStyles = makeStyles((theme) => ({
  container: {
    padding: '12px 0',
  },
  name: {
    fontSize: 14,
    color: theme.palette.text.primary
  }
}));

const calcProgress = (greather, pontos) => {
  return (pontos * 100) / greather;
};

const TopAreaItem = ({ businessUnitName, areaName, pointsCalc, count }) => {
  const classes = topAreaItemStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={4}>
        <div className={classes.name}>
          {businessUnitName} / {areaName}
        </div>
      </Grid>
      <Grid item xs={6} >
        <BorderLinearProgess
          variant="determinate"
          value={pointsCalc}
          style={{ marginTop: 4 }}
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'end' }}>
        <span style={{ fontSize: 14, color: theme.palette.neutrals.low.light }}>
          { count } {t('ideias').toLowerCase()}
        </span>
      </Grid>
    </Grid>
  );
};

function TopAreas({ topAreas, loadingTopAreas }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [greatherPoints, setGreatherPoints] = useState(0);

  useEffect(() => {
    if (topAreas.length) {
      setGreatherPoints(topAreas[0].count);
    }
  }, [topAreas]);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDownloadCsv() {
    const filters = dashboardService.getFilters();
    filters.delimiter = ";";
    filters.reportType = "top-areas";
    dispatch(dashboardActions.getTopFiveAreas(filters));
  }

  return (
      <Card elevation={0} className={classes.card}>
        {loadingTopAreas ? (
          <SkeletonDashboardTops />
        ) : (
          <>
            <Box className={classes.box}>
              <Typography className={classes.title}>
                {t('top_5_areas_que_mais_receberam_ideias')}
              </Typography>
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                MenuListProps={{ disablePadding: true }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Button
                  variant="contained" 
                  startIcon={<GetAppIcon />}
                  className={classes.buttonCsv}
                  onClick={handleClickDownloadCsv}
                >
                  exportar para csv
                </Button>
              </Menu>
            </Box>

            {topAreas.map((t, index) => (
              <TopAreaItem
                key={index}
                pointsCalc={calcProgress(greatherPoints, t.count)}
                {...t}
              />
            ))}
          </>
        )}
    </Card>
  );
}

export { TopAreas };
