import {
  styled,
  IconButton
} from '@material-ui/core';

export const Button = styled(IconButton)(({ theme }) => ({
  width: 44,
  height: 44,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
  transition: 'ease-in-out 0.4s',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));
