export const engagementConstants = {
  SET_ENGAGEMENT_TAB: "SET_ENGAGEMENT_TAB",
  GET_ENGAGEMENT_ITEMS_REQUEST: "GET_ENGAGEMENT_ITEMS_REQUEST",
  GET_ENGAGEMENT_ITEMS_SUCCESS: "GET_ENGAGEMENT_ITEMS_SUCCESS",
  GET_ENGAGEMENT_ITEMS_FAILURE: "GET_ENGAGEMENT_ITEMS_FAILURE",
  CLEAR_ENGAGEMENT_ERRORS: "CLEAR_ENGAGEMENT_ERRORS",

  SEND_COMMENT_IDEA_REQUEST: 'SEND_COMMENT_IDEA_REQUEST',
  SEND_COMMENT_IDEA_SUCCESS: 'SEND_COMMENT_IDEA_SUCCESS',
  SEND_COMMENT_IDEA_FAILURE: 'SEND_COMMENT_IDEA_FAILURE'
}