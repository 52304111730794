import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Grid, FormControl, TextField, InputLabel, Select, Card, MenuItem, Checkbox } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';

import { CardThumbnail } from '../CreateIdeaDialogs/CardThumbnail';
import { ideaEditStyles } from "./IdeaEditStyles";
import { ideaUpdateActions } from "../../actions";
import { AvatarIconWithText } from "../Shared";
import { useWindowSize } from '../../hooks';
import { truncate } from '../../helpers';
import config from '../../config';

export const IdeaEditGeneralInnovation = ({ ideaUpdate }) => {
  const { t } = useTranslation();
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const [width] = useWindowSize();

  function handleSetFields(e, index) {
    const { value } = e.target;
    dispatch(ideaUpdateActions.setFieldsAnswer({ answer: value, index }));
  }

  function handleChangeInnovationCase(data) {
    if (data) {
      dispatch(ideaUpdateActions.setInnovationCaseField(data.option));
    } else {
      dispatch(ideaUpdateActions.clearInnovationCaseField());
    }
  }

  const SelectProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <Grid item xs={12}>
      <Card elevation={0} className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AvatarIconWithText
              icon={<EmojiObjectsIcon />}
              text="Inovação"
            />
          </Grid>

          {ideaUpdate?.innovationQuestions.map((item, index) => (
            <Grid item xs={12} key={item._id}>
              {(!item?.isSelect && !item?.attachmentRequired) && (
                <TextField
                  fullWidth
                  multiline
                  minRows={width > config.RESPONSIVE_MOBILE ? 3 : 5}
                  variant="filled"
                  id={item._id}
                  name="answer"
                  label={width > config.RESPONSIVE_MOBILE ? item.question : truncate(item.question, 83, '...')}
                  defaultValue={item?.answer}
                  onBlur={(e) => handleSetFields(e, index)}
                  style={{ marginBottom: 30 }}
                />
              )}

              {item._id === '65d428327aee51a950e319b5' && (
                <Autocomplete
                  multiple
                  fullWidth
                  disableCloseOnSelect
                  loadingText={t('carregando')}
                  noOptionsText={t('sem_opcoes')}
                  clearText={t('excluir_inovacao')}
                  clearOnEscape={true}
                  options={item?.valuesToSelect}
                  loading={item?.valuesToSelect.length === 0}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <TextField {...params} label={item.question} variant="filled" />}
                  value={item?.valuesToSelect.filter(item => item.checked)}
                  onChange={(_e, _items, _options, details) => handleChangeInnovationCase(details)}
                  renderOption={(value) => {
                    return (
                      <li>
                        <Checkbox
                          color="primary"
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckboxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={value.checked}
                          />
                        {value.label}
                      </li>
                    )
                  }}
                />
              )}
              
              {(item?.isSelect && !item?.attachmentRequired) && item._id !== '65d428327aee51a950e319b5' && (
                <FormControl fullWidth variant="filled">
                  <InputLabel>{item.question}</InputLabel>
                    <Select
                      MenuProps={SelectProps}
                      defaultValue={item?.answer}
                      onChange={(e) => handleSetFields(e, index)}
                      style={{ marginBottom: 30 }}
                    >
                    {item?.valuesToSelect.map((value, index) => (
                      <MenuItem value={value} key={index}> {value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          ))}

          <Grid item xs={12}>
            <Box className={classes.cardThumbnail}>
              <CardThumbnail type="thumbnail" image={ideaUpdate?.cardThumbnail} isEdit={true} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
