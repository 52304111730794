import { articleConstants, timelineConstanst } from '../constants';

const initialState = {
  newArticle: {
    title: "",
    description: "",
    thumbnail: {},
    banner: {},
    attachmentQuestionOne: {},
    attachmentQuestionTwo: {},
    authors: [],
    status: false,
    highlight: false,
    innovationQuestions: []
  },
  ideas: [],
  articles: [],
  classifications: [],
  highlightArticles: [],
  authors: [],
  articleQuestions: [],
  loading: false,
  likeLoading: false,
  viewEmptyState: false,
  articleDetails: {
    title: "",
    description: "",
    thumbnail: {},
    banner: {},
    attachmentQuestionOne: {},
    attachmentQuestionTwo: {},
    authors: [],
    status: false,
    highlight: false,
    innovationQuestions: []
  },
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 50,
    filter: { field: 'name', value: '' },
    hasNextPage: false,
  },
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case articleConstants.SET_ARTICLE_HIGHLIGHT:
      return {
        ...state,
        newArticle: { ...state.newArticle, highlight: action.payload }
      }

    case articleConstants.SET_ARTICLE_HIGHLIGHT_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, highlight: action.payload }
      }

    case articleConstants.SET_ARTICLE_STATUS:
      return {
        ...state,
        newArticle: { ...state.newArticle, status: action.payload }
      }

    case articleConstants.SET_ARTICLE_STATUS_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, status: action.payload }
      }

    case articleConstants.SET_ARTICLE_AUTHORS:
      return {
        ...state,
        newArticle: { ...state.newArticle, authors: action.payload }
      }

    case articleConstants.SET_ARTICLE_AUTHORS_EDIT:
      return {
        ...state,
        articleDetails: { ...state.articleDetails, authors: action.payload }
      }

    case articleConstants.GET_ARTICLE_BY_ID_SUCCESS:
      return {
        ...state,
        articleDetails: action.payload
      }

    case articleConstants.GET_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case articleConstants.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.data,
        loading: false,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit
        },
      };

    case articleConstants.GET_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case articleConstants.GET_HIGHLIGHT_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case articleConstants.GET_HIGHLIGHT_ARTICLES_SUCCESS:
      return {
        ...state,
        highlightArticles: action.payload.data,
        loading: false,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit,
          query: action.payload.query
        },
      };

    case articleConstants.GET_HIGHLIGHT_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case articleConstants.GET_MORE_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: [...action.payload.data],
        loading: false,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit,
          searchText: action.payload.paging.searchText
        }
      };

    case articleConstants.GET_MORE_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case articleConstants.SET_ARTICLE_IMAGE:
      const { type, image, isEditImage } = action.payload;
      const fieldImageEdit = isEditImage ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [fieldImageEdit]: {
          ...state[fieldImageEdit],
          [type]: image,
        }
      };

    case articleConstants.REMOVE_ARTICLE_IMAGE:
      const { type: imageType, edit } = action.payload;
      const fieldEdit = edit ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [fieldEdit]: {
          ...state[fieldEdit],
          [imageType]: {},
        }
      };

    case articleConstants.SET_IMAGE_TO_REMOVE_ARTICLE:
      const { type: imageTypeToRemove } = action.payload;
      return {
        ...state,
        newArticle: {
          ...state.newArticle,
          [imageTypeToRemove]: {},
        }
      };

    case articleConstants.SET_ARTICLE:
      const { article } = action;
      return {
        ...state,
        newArticle: { ...state.newArticle, description: article.description, title: article.title },
      };

    case articleConstants.SET_PROP_ARTICLE:
      const { value, name, isEdit } = action;
      const field = isEdit ? 'articleDetails' : 'newArticle';
      return {
        ...state,
        [field]: {
          ...state[field],
          [name]: value,
        },
      };

    case articleConstants.CREATE_ARTICLE_REQUEST:
      return state;

    case articleConstants.CREATE_ARTICLE_SUCCESS:
      return state;

    case articleConstants.CREATE_ARTICLE_FAILURE:
      return state;

    case articleConstants.CLEAR:
      return initialState;

    case articleConstants.GET_ARTICLES_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case articleConstants.GET_ARTICLES_QUESTIONS_SUCCESS:
      return {
        ...state,
        articleQuestions: action.payload.data,
        loading: false
      };

    case articleConstants.GET_ARTICLES_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case articleConstants.ARTICLE_LOAD_QUESTIONS:
      return {
        ...state,
        newArticle: {
          ...state.newArticle,
          innovationQuestions: action.payload
        }
      }

    case articleConstants.ARTICLE_LOAD_ARTICLES_DETAILS:
      return {
        ...state,
        newArticle: action.payload
      }

    case articleConstants.SET_ARTICLES_QUESTIONS_FIELD_ANSWER:
      return {
        ...state,
        newArticle: {
          ...state.newArticle,
          innovationQuestions: state.newArticle.innovationQuestions.map((item, index) => {
            if (index === action.payload.index) {
              return {
                ...item,
                answer: action.payload.answer
              }
            }
            return item
          })
        }
      }

    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_REQUEST:
      return {
        ...state,
        loading: true,
        viewEmptyState: false
      }

    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_SUCCESS:
      if (action.payload.ideas.length > 0) {
        if (action.payload.paging.page === 1) { 
          return {
            ...state,
            loading: false,
            viewEmptyState: false,
            ideas: action.payload.ideas,
            paging: {
              ...state.paging,
              page: action.payload.paging.page,
              sort: action.payload.paging.sort,
              limit: action.payload.paging.limit,
              hasNextPage: action.payload.paging.hasNextPage
            }
          }
        } else {
          return {
            ...state,
            loading: false,
            viewEmptyState: false,
            ideas: [...action.payload.ideas],
            paging: {
              ...state.paging,
              page: action.payload.paging.page,
              sort: action.payload.paging.sort,
              limit: action.payload.paging.limit,
              hasNextPage: action.payload.paging.hasNextPage
            }
          }
        }
      } else {
        return {
          ...state,
          loading: false,
          viewEmptyState: true
        }
      }
  
    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_FAILURE:
      return {
        ...state,
        loading: false,
        viewEmptyState: true
      }

    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ideas: action.payload.ideas,
        paging: {
          ...state.paging,
          page: action.payload.paging.page,
          sort: action.payload.paging.sort,
          limit: action.payload.paging.limit
        }
      }
  
    case articleConstants.GET_IDEAS_WITH_INFINITE_SCROLL_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
      }
        
    case articleConstants.ARTICLES_CLEAR_ALL_STATES:
      return initialState;

    case timelineConstanst.SET_LIKED_IDEA:
      return {
        ...state,
        ideas: state.ideas.map(idea => {
          if (idea._id === action.payload.id) {
            const upsQuantity = idea.likedLength ?? idea.ups.length;
            const newLikedLength = action.payload.liked ? upsQuantity + 1 : upsQuantity - 1;
            const updatedUps = action.payload.liked
            ? [...idea.ups, action.payload.userId]
            : idea.ups.filter(up => up !== action.payload.userId);

            return {
              ...idea,
              liked: action.payload.liked,
              likedLength: newLikedLength,
              ups: updatedUps
            }
          }

          return idea;
        }),
      };
    
    case articleConstants.CASE_LOAD_CLASSIFICATIONS:
      return {
        ...state,
        classifications: action.payload,
      }

    case articleConstants.CASE_CHANGE_CLASSIFICATIONS:
      if (action.payload.value === 'SELECT_ALL') {
        return {
          ...state,
          classifications: state.classifications.map(item => ({
            ...item,
            checked: action.payload.checked ? false : true
          }))
        }
      } else {
        return {
          ...state,
          classifications: state.classifications.map(item => {
            if (item.value === action.payload.value) {
              return {
                ...item,
                checked: !action.payload.checked
              }
            }
            return item;
          })
        }
      }

    case articleConstants.CASE_CLEAR_CLASSIFICATIONS:
      return {
        ...state,
        classifications: state.classifications.map(item => ({
          ...item,
          checked: false,
        }))
      }

    default:
      return state;
  }
};

export { articlesReducer };
