import React, { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { articleActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ImagineDialogConfirmation } from '../ImagineTemplates';
import { Card } from './articlesStyles';

function ArticleProgressCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const { newArticle } = useSelector((state) => state.articlesReducer);

  function handleLoadingButton() {
    setLoading(!loading);
  }

  function handleDelete(id) {
    dispatch(articleActions.deleteArticle(id, t('sua_inovacao_foi_excluida')));
  }

  function sendArticle() {
    if (props.isEdit) {
      dispatch(articleActions.putArticle(newArticle, t('sua_inovacao_foi_salva')));
    } else {
      dispatch(articleActions.createArticle(newArticle, t('sua_inovacao_foi_criada')));
    }
  }

  return (
    <Card elevation={0}>
      <ImagineDialogConfirmation />

      <Box>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          color="primary"
          onClick={() => { handleLoadingButton(); sendArticle(); }}
        >
          {props.isEdit ? t('salvar_inovacao') : t('publicar_inovacao')}
        </Button>

        {props.isEdit && <Button
          style={{ width: '100%', marginTop: 10 }}
          variant="outlined"
          color="primary"
          onClick={() => handleDelete(id)}
        >
          {t('excluir_inovacao')}
        </Button>}
      </Box>
    </Card>
  );
}

export { ArticleProgressCard };
