import React from 'react';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { history } from '../../helpers';
import ImagineSlider from '../Shared/ImagineSlider';
import { ArticleCard } from './ArticleCard';
import { ArticleNextArrow, ArticlePrevArrow } from './ArticleSliderOptions';
import config from '../../config';
import { useWindowSize } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  seeAllContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.auxiliary.beige.main
  }
}));

function HighLightArticleList() {
  const { highlightIdeas } = useSelector(state => state.timeline);
  const classes = useStyles();
  const { t } = useTranslation();
  const [width] = useWindowSize();

  return (
    <>
      <Card className={classes.row} elevation={0} onClick={() => history.push('/articles')}>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {t('acesse_projetos_da_#InovaçãoAncar_no_nosso_mural')}
          </Typography>
        </Box>

        <Box className={classes.seeAllContainer}>
          <Typography className={classes.textSeeAll}>{t('ver_todas')}</Typography>
          <KeyboardArrowDown />
        </Box>
      </Card>

      {width > config.RESPONSIVE_MOBILE ? (
        <ImagineSlider
          settings={{
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 7000,
            nextArrow: <ArticleNextArrow />,
            prevArrow: <ArticlePrevArrow />
          }}
        >
          {highlightIdeas?.map((item, index) => (
            <ArticleCard
              key={index}
              idea={item}
              image={item?.cardThumbnail?.url ?? ""}
              link={`/idea/${item?._id}/general`}
              index={index}
            />
          ))}
        </ImagineSlider>
      ) : (
        highlightIdeas?.map((item, index) => (
          <ArticleCard
            key={index}
            idea={item}
            image={item?.cardThumbnail?.url ?? ""}
            link={`/idea/${item?._id}/general`}
            index={index}
          />
        ))
      )}
    </>
  );
}

export { HighLightArticleList };
