import { Card, CardMedia, Box, makeStyles, Typography } from "@material-ui/core";
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { truncate } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    margin: 10,
    cursor: 'pointer'
  },
  titleContainer: {
    height: 45,
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      height: 30,
    }
  },
  descriptionContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 6,
    height: 100,

    [theme.breakpoints.down('sm')]: {
      height: 50,
    }
  },
  media: {
    width: '100%',
    height: 150,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}));

export function ContentCard({ item }) {
  const classes = useStyles();

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Card elevation={0} className={classes.card} onClick={() => handleClick(item.link)}>
      <CardMedia image={item?.thumbnail?.url ?? imageCard} className={classes.media} />
      
      <Box className={classes.titleContainer}>
        <Typography variant="subtitle2">{item.title}</Typography>
      </Box>

      <Box className={classes.descriptionContainer}>
        <Typography variant="body2">{truncate(item.description, 120)}</Typography>
      </Box>
    </Card>
  );
}