import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useCreateIdeaController } from '../hooks/useCreateIdeaController';
import { FieldDinamic } from './FieldDinamic';
import config from '../../../config';

export function ReviewIdeaContent() {
  const { t } = useTranslation();
  const {
    ideaCreate,
    redirectToEditTitle,
    redirectToEditArea,
    redirectToEditChallenge,
    redirectToEditClassification,
    redirectToEditPhoto,
    redirectToEditLinks,
    redirectToEditFile,
    redirectToEditVideo
  } = useCreateIdeaController();

  return (
    <Grid container spacing={3}>
      <FieldDinamic
        name="title"
        label={t('titulo')}
        value={ideaCreate.name}
        onClick={redirectToEditTitle}
      />

      <FieldDinamic
        name="description"
        label={t('descricao_da_ideia')}
        value={ideaCreate.description}
        onClick={redirectToEditTitle}
      />

      <FieldDinamic
        name="problem"
        label={t('problema_identificado')}
        value={ideaCreate.problem}
        onClick={redirectToEditTitle}
      />

      <FieldDinamic
        name="solution"
        label={t('solucao_proposta')}
        value={ideaCreate.solution}
        onClick={redirectToEditTitle}
      />

      <FieldDinamic
        name="participants"
        label={t('participantes')}
        value={ideaCreate.contributors}
        onClick={redirectToEditTitle}
      />

      {config.SHOW_IDEAS_BY_STEP_FLUX && (
        <FieldDinamic
          name="costs"
          label={t('estrutura_de_custos')}
          subTitle={[t('custos previstos'), t('retorno previsto')]}
          value={[ideaCreate?.estimatedExecutionCost, ideaCreate?.estimatedFinancialReturn]}
          onClick={redirectToEditTitle}
        />
      )}

      <FieldDinamic
        name="images"
        label={t('imagens')}
        value={ideaCreate?.images}
        onClick={redirectToEditPhoto}
      />

      <FieldDinamic
        name="video"
        label={t('video')}
        value={ideaCreate?.video?.preview}
        onClick={redirectToEditVideo}
      />

      <FieldDinamic
        name="files"
        label={t('documentos')}
        value={ideaCreate?.files}
        onClick={redirectToEditFile}
      /> 

      <FieldDinamic
        name="links"
        label={t('links')}
        value={ideaCreate?.links}
        onClick={redirectToEditLinks}
      /> 

      <FieldDinamic
        name="area"
        label={t('abrangencia')}
        subTitle={[t('unidade_de_negocio'), t('local'), t('area')]}
        value={ideaCreate?.selectedBusiness}
        onClick={redirectToEditArea}
      />

      {ideaCreate?.challenge?.name !== 'Ideia Aberta' && (
        <FieldDinamic
          name="challenge"
          label={t('desafio')}
          value={ideaCreate?.challenge?.name}
          onClick={redirectToEditChallenge}
        />
      )}

      <FieldDinamic
        name="classification"
        label={t('classificacao')}
        subTitle={['categoria do case']}
        value={ideaCreate?.personalizedClassifications}
        onClick={redirectToEditClassification}
      />
    </Grid>
  );
}